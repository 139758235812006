<template>
  <v-chip :color="color" class="text-capitalize">
    {{ text }}
  </v-chip>
</template>

<script setup>
const props = defineProps({
  value: {
    required: true,
  },
});

const color = computed(() => (props.value ? "success" : "error"));

const text = computed(() => (props.value ? "Ativo" : "Inativo"));
</script>

<style>
</style>